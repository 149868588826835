import axios from 'axios'

import * as constants from '@/components/js/constants.js'

/*
Default axios instance for making requests to the api
*/
export const apiRequest = axios.create({
  baseURL: `${constants.SITE_URL}${constants.API_VERSION_PREFIX}`,
  timeout: 10000,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})

/*
Takes the input data and processes them into id, string pairs suitable for
use with the select dropdown using the `field`
*/
export function selectOptions (data, field) {
  const returnData = []

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      returnData[i] = { id: item.id, str: item[field] }
    }
  }

  return returnData
}

/*
Performs a `GET` request to the requested api path and returns the response
*/
export function getRequest (
  path,
  queryString = null,
  successCallback,
  errorCallback
) {
  // Construct the url for the request
  let url = `${path}/?format=json`
  // If queryString provided, update url with parameters
  if (queryString) {
    url = `${url}&${queryString}`
  }

  // Make the get request
  apiRequest.get(url)
    .then(function (response) {
      // handle success
      successCallback(response)
    })
    .catch(function (error) {
      // handle error
      if (errorCallback) {
        errorCallback(error)
      } else {
        console.log(error)
      }
    })
}

/*
Performs a `POST` request to the requested api path and returns the response
*/
export function postRequest (path, data, successCallback, errorCallback) {
  // Make the post request, and return the result to the callbacks
  apiRequest.post(`${path}/`, data).then(successCallback, errorCallback)
}

/*
Returns `DEFAULT_STR` or an optional `default`
if input value is null or an empty string
*/
export function defaultIfNone (value, defaultStr = constants.DEFAULT_STR) {
  if (value == null || value === '') {
    return defaultStr
  } else {
    return value
  }
}

/*
Returns a formatted date string from an input ISO style datetime
*/
export function formatDate (value) {
  // Make sure value is not `null`
  if (value) {
    const date = new Date(value)
    // `toDateString` will return "Invalid Date" if
    //  input value is not a valid datetime string
    return date.toDateString() === 'Invalid Date'
      ? constants.DEFAULT_STR : date.toDateString()
  } else {
    return constants.DEFAULT_STR
  }
}

/*
If input `value` is a valid integer, convert to a string,
otherwise return '0' string
*/
export function intToString (value) {
  // Convert incoming value to integer, in case input is a string
  value = Number.parseInt(value)

  // if input is a valid number, convert the integer to string
  if (!Number.isNaN(value)) {
    return value.toString()
  } else {
    return '0'
  }
}

/*
If input `value` is a valid integer, converts to a pounds string
with `DECIMAL_PLACES` decimal places. If `toLocale` is set to `true`,
return a string converted to locale format with £ symbol
Otherwise return decimal string
if input `value` is not a valid integer
  If `toLocale` is set to `true`, return `DEFAULT_STR`
  Otherwise return empty string
*/
export function penceToPounds (
  value,
  toLocale = true,
  hideInsignificantDigits = false
) {
  // Set default return string
  var returnVal = toLocale ? constants.DEFAULT_STR : ''
  // Convert incoming value to integer, in case input is a string
  value = Number.parseInt(value)

  // if input is a valid number, convert the integer to a decimal string
  if (!Number.isNaN(value)) {
    const decStr = Number(value * (10 ** -(constants.DECIMAL_PLACES)))

    if (toLocale) {
      // if no signification decimals, hide them
      if (hideInsignificantDigits && decStr % 1 === 0) {
        returnVal = decStr.toLocaleString(
          constants.DEFAULT_LOCALE,
          {
            maximumFractionDigits: 0
          }
        )
      } else {
        returnVal = decStr.toLocaleString(
          constants.DEFAULT_LOCALE,
          {
            minimumFractionDigits: constants.DECIMAL_PLACES,
            maximumFractionDigits: constants.DECIMAL_PLACES
          }
        )
      }
      if (returnVal < 0) {
        returnVal = `-£${returnVal * -1}`
      } else {
        returnVal = `£${returnVal}`
      }
    } else {
      if (hideInsignificantDigits && decStr % 1 === 0) {
        returnVal = decStr.toFixed(0)
      } else {
        returnVal = decStr.toFixed(constants.DECIMAL_PLACES)
      }
    }
  }

  return returnVal
}

/*
Returns "null" if `value` is `null` or an empty string,
otherwise converts `value` from an input pounds decimal to a pence integer
*/
export function poundsToPence (value) {
  // remove decorations if they exist
  if (typeof (value) === 'string') {
    // if formatted as
    // £[space][number],[number].[number] e.g. £10,000.00, £ 100.01
    if (value.search(/£[ ]*[\d]*[,]*[\d]*.[\d]*/i) > -1) {
      // remove £ sign and commas
      value = value.replaceAll('£', '').replaceAll(',', '')
    }
  }

  // Convert incoming value to float, in case input is a string
  value = Number.parseFloat(value)

  // if input is a valid number, convert the pounds decimal to a pence integer
  if (!Number.isNaN(value)) {
    return Math.round(value * (10 ** constants.DECIMAL_PLACES))
  } else {
    return null
  }
}

/**
 *
 * @param {*} projectLineItem
 * @returns prep_time_days / 100, as we store it as an
 * integer to represent a decimal
 */
export function prepTimeDays (projectLineItem) {
  return projectLineItem.prep_time_days / 100
}

/**
 *
 * @param {*} projectLineItem
 * @returns work_time_days / 100, as we store it as an
 * integer to represent a decimal
 */
export function workTimeDays (projectLineItem) {
  return projectLineItem.work_time_days / 100
}

/**
 *
 * @param {*} projectLineItem
 * @returns quantity / 100, as we store it as an integer to represent a decimal
 */
export function quantity (projectLineItem) {
  if (projectLineItem.quantity === null) return null
  return projectLineItem.quantity / 100
}

/**
 * stdOtRateCoefficient - calculates the ot rate
 *    coefficient for a project line item
 * `apa_ot_grade.coefficient` are saved as integers for easier maths,
 *    so a coefficient of 1 equals 100...
 */
export function stdOtRateCoefficient (projectLineItem) {
  // Some line items do not have an `apa_ot_grade`
  if (projectLineItem.apa_ot_coefficient) {
    return projectLineItem.apa_ot_coefficient
  }
  return projectLineItem.line_item.apa_ot_grade.coefficient || 100
}

/**
 * nightOtRateCoefficient - calculates the night ot rate
 *  coefficient for a project line item
 * `night_ot_rate.coefficient` are saved as integers for easier maths,
 *  so a coefficient of 1 equals 100...
 */
export function nightOtRateCoefficient (projectLineItem) {
  // Some line items do not have an `night_ot_rate`
  if (projectLineItem.night_ot_rate_coefficient) {
    return projectLineItem.night_ot_rate_coefficient
  }
  return projectLineItem.line_item.night_ot_rate.coefficient || 100
}

/**
 * costRate - calculates the cost rate of a project line item
 */
export function costRate (projectLineItem) {
  if (!customPli(projectLineItem)) {
    if (projectLineItem.internal_cost_pence != null) {
      return projectLineItem.internal_cost_pence
    }
    return projectLineItem.line_item.rate_pence
  } else {
    return projectLineItem.internal_cost_pence
  }
}

/**
 * stdOtRate - calculates the overtime rate of a project line item
 */
export function stdOtRate (projectLineItem) {
  if (stdOtValid(projectLineItem)) {
    return (stdOtRateCoefficient(projectLineItem) / 100) *
      (costRate(projectLineItem) / constants.HOURS_IN_WORKING_DAY)
  }
  return null
}

/**
 * nightOtRate - calculates the night overtime rate of a project line item
 */
export function nightOtRate (projectLineItem) {
  if (nightOtValid(projectLineItem)) {
    return (nightOtRateCoefficient(projectLineItem) / 100) *
      (costRate(projectLineItem) / constants.HOURS_IN_WORKING_DAY)
  }
  return null
}

/**
 * @param {*} projectLineItem
 * @returns Boolean
 * Night OT is valid if night_ot_rate_coefficient is not `null`
 */
export function nightOtValid (projectLineItem) {
  return projectLineItem.line_item !== null &&
    projectLineItem.line_item.night_ot_rate !== null
}

/**
 *
 * @param {*} projectLineItem
 * @returns Boolean
 * OT Rate is valid if apa_ot_coefficient is not `null`
 */
export function stdOtValid (projectLineItem) {
  return projectLineItem.line_item !== null &&
    projectLineItem.line_item.apa_ot_grade !== null
}

/**
 * breakEvenCost - calculates the break even cost of a project line item
 */
export function breakEvenCost (projectLineItem) {
  let value = 0

  if (unitDays(projectLineItem)) {
    value = (costRate(projectLineItem) *
      (prepTimeDays(projectLineItem) + workTimeDays(projectLineItem)))

    if (stdOtValid(projectLineItem)) {
      value += stdOtRate(projectLineItem) * projectLineItem.std_ot_hours
    }

    if (nightOtValid(projectLineItem)) {
      value += nightOtRate(projectLineItem) * projectLineItem.night_ot_hours
    }
    if (quantity(projectLineItem) !== null) {
      value *= quantity(projectLineItem)
    }
  } else {
    value = costRate(projectLineItem) * quantity(projectLineItem)
  }
  return Number(value)
}

/**
 * isInternal - check whether project line item is internal or external cost
 */
export function isInternal (projectLineItem) {
  if (projectLineItem.internal) {
    return 'Yes'
  } else {
    return 'No'
  }
}

/**
 * externalBECost - calculate projectLineItem external break even cost
 * @param {*} projectLineItem
 * @returns Number
 */
export function externalBECost (projectLineItem) {
  if (isInternal(projectLineItem) === 'No') {
    return breakEvenCost(projectLineItem)
  } else {
    return 0
  }
}

/**
 * internalBECost - calculate projectLineItem internal break even cost
 * @param {*} projectLineItem
 * @returns Number
 */
export function internalBECost (projectLineItem) {
  if (isInternal(projectLineItem) === 'Yes') {
    return breakEvenCost(projectLineItem)
  } else {
    return 0
  }
}

/**
 * billableRate - calculates the billable rate of a project line item
 */
export function billableRate (projectLineItem) {
  if (!customPli(projectLineItem)) {
    if (projectLineItem.external_cost_pence !== null) {
      return projectLineItem.external_cost_pence
    } else {
      return projectLineItem.line_item.rate_pence
    }
  } else {
    return projectLineItem.external_cost_pence
  }
}

export function billableStdOtRate (projectLineItem) {
  if (stdOtValid(projectLineItem)) {
    return (stdOtRateCoefficient(projectLineItem) / 100) *
    (billableRate(projectLineItem) / constants.HOURS_IN_WORKING_DAY)
  }
  return null
}

export function billableNightOtRate (projectLineItem) {
  if (nightOtValid(projectLineItem)) {
    return (nightOtRateCoefficient(projectLineItem) / 100) *
    (billableRate(projectLineItem) / constants.HOURS_IN_WORKING_DAY)
  }
  return null
}

/**
 * totalBillable - calculates the total billable of a project line item
 */
export function totalBillable (projectLineItem) {
  let value = 0

  if (unitDays(projectLineItem)) {
    value = billableRate(projectLineItem) *
      (workTimeDays(projectLineItem) + prepTimeDays(projectLineItem))

    if (stdOtValid(projectLineItem)) {
      value += billableStdOtRate(projectLineItem) * projectLineItem.std_ot_hours
    }

    if (nightOtValid(projectLineItem)) {
      value += billableNightOtRate(projectLineItem) *
        projectLineItem.night_ot_hours
    }

    if (quantity(projectLineItem) !== null) {
      value *= quantity(projectLineItem)
    }
  } else {
    value = billableRate(projectLineItem) * quantity(projectLineItem)
  }

  return Number(value)
}

/*
Return true if there is no related `line_item`
associated withe the `ProjectLineItem`, otherwise false
*/
export function customPli (projectLineItem) {
  return projectLineItem.line_item === null
}

/*
if customPli
  Return true if the selected unit from the dropdown is '1' (the id of 'Day')
  otherwise false
otherwise
  Return true if the line item unit name is 'Day', otherwise false
*/
export function unitDays (projectLineItem) {
  if (customPli(projectLineItem)) {
    return String(projectLineItem.unit) === '1'
  } else {
    return projectLineItem.line_item.unit.name === 'Days' || String(projectLineItem.line_item.unit.id) === '1'
  }
}

/*
Filters project line items array by category id either
using the line_item.category relationship or
category field for custom project line items
*/
export function filterProjectLineItemsByCategory (
  projectLineItems, categoryId
) {
  if (!projectLineItems || !categoryId) {
    return []
  }
  return projectLineItems.filter(
    function (pli) {
      if (pli.line_item !== null) {
        return pli.line_item.category === categoryId
      } else {
        return pli.category === categoryId
      }
    }
  )
}

/**
 * returns rec invoiced amount for a project line item
 */
export function recInvoiced (projectLineItem) {
  if (projectLineItem && projectLineItem.rec) {
    return projectLineItem.rec.invoice_amount
  }
  return 0
}

/**
 * return true if pli has a rec marked as paid
 */
export function recPaid (projectLineItem) {
  if (projectLineItem && projectLineItem.rec) {
    return projectLineItem.rec.paid
  }
  return false
}

/**
 * function returns full path for static images directory
 * @returns String
 */
export function imagesPath () {
  if (process.env.NODE_ENV === 'production') {
    return `${window.location.origin}/static/images`
  } else {
    return `${window.location.origin}/images`
  }
}

/**
 * return True if projectLineItem is considered "Used".
 * Used projectlineitems appear in rec mode and budget summary
 * @param {*} projectlineitem
 * @returns Boolean
 */
export function lineUsed (projectlineitem) {
  if (
    projectlineitem.prep_time_days ||
    projectlineitem.work_time_days ||
    projectlineitem.quantity
  ) {
    return true
  }
  return false
}

export function truncateNotes (notes) {
  if (!notes) {
    notes = ''
  }

  const n = notes.replaceAll('\n', '. ')

  if (n.length > 100) {
    return n.slice(0, 100).concat('...')
  }
  return n
}

/**
 * function defines a pdfMake table layout
 * used mostly for adding spacing between unit and cost
 * based on noBorder layout from https://github.com/bpampuch/pdfmake/blob/master/src/tableLayouts.js
 */
export const pdfMakeTableLayouts = {
  customLayout: {
    hLineWidth: function hLineWidth (i) {
      return 0
    },
    vLineWidth: function vLineWidth (i) {
      return 0
    },
    paddingLeft: function paddingLeft (i) {
      // return i && 4 || 0
      return i === 2 ? 100 : 0
    },
    paddingRight: function paddingRight (i, node) {
      return i < node.table.widths.length - 1 ? 4 : 0
    }
  }
}

/**
 * function creates PDFMake document definition object
 * for budget summary print out from project object. see docs:
 * https://pdfmake.github.io/docs/0.1/document-definition-object/
 * @param {object} project
 * @returns {object} document definition
 */
export function budgetSummaryDocumentDefinition (
  project,
  DepartmentsAndProjectLineItems,
  totals,
  showAllItems = false
) {
  let stripe = true
  function stripedRows (cells) {
    if (stripe) {
      stripe = false
      cells.forEach(cell => {
        cell.fillColor = '#f5f5f5'
        // cell.fillOpacity = '50%'
      })
      return cells
    } else {
      stripe = true
      return cells
    }
  }

  /**
   * This returns a string count of plis units
   * @param {*} pli
   * @returns {String} String in format {x} {units}
   */
  function units (pli) {
    if (pli.line_item) {
      if (pli.line_item.unit?.id === 1) {
        const days = (pli.work_time_days + pli.prep_time_days) / 100
        if (pli.quantity) {
          return `${pli.quantity / 100} x ${days} Days`
        }
        return `${days} Days`
      }
      if (pli.line_item.unit?.id === 2) {
        return `${pli.quantity / 100} Units`
      }
    } else {
      if (pli.unit && pli.unit === 1) {
        return `${(pli.work_time_days + pli.prep_time_days) / 100} Days`
      }
      if (pli.unit && pli.unit === 2) {
        return `${pli.quantity / 100} Units`
      }
    }
  }

  /**
   * This function could be optimized, but it works for now
   * @param {*} buildDepRows
   * @returns Array[] of table rows
   */
  function buildDepRows (depsAndPlis, showAllItems = false) {
    const response = []
    Object.entries(depsAndPlis).map(dep => {
      if (
        !dep[1].projectLineItems.filter(pli => lineUsed(pli)).length <= 0 &&
        !dep[1].totals.totalTotalBillable <= 0
      ) {
        // push dep row
        response.push(
          stripedRows([
            { text: dep[1].department.name, style: 'RowTextDepartment' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: penceToPounds(dep[1].totals.totalTotalBillable), style: 'RowTextDepartment', alignment: 'right' }
          ])
        )
        // push pli rows
        dep[1].projectLineItems.map(pli => {
          if (lineUsed(pli)) {
            response.push(
              stripedRows([
                { text: pli.line_item ? pli.line_item.name : pli.name, margin: [3, 0, 0, 0], style: 'RowText' },
                { text: truncateNotes(pli.notes), style: 'RowText' },
                showAllItems ? { text: penceToPounds(pli.external_cost_pence), style: 'RowText' } : { text: '', style: 'RowText' },
                { text: units(pli), style: 'RowTextUnits' },
                showAllItems ? { text: penceToPounds(totalBillable(pli)), style: 'RowText', alignment: 'right' } : { text: '', style: 'RowText' }
              ])
            )
          }
        })
      }
    })
    return response
  }

  function buildTableRows (showAllItems) {
    return [
      [
        { text: 'Item', style: 'tableHeader' },
        { text: 'Notes', style: 'tableHeader' },
        showAllItems ? { text: 'Rate', style: 'tableHeader' } : { text: '', style: 'tableHeader' },
        { text: 'Days/Units', style: 'tableHeader', alignment: 'right' },
        { text: 'Cost', style: 'tableHeader', alignment: 'right' }
      ],
      ...buildDepRows(DepartmentsAndProjectLineItems, showAllItems)
    ]
  }

  function buildTotalsRows () {
    const rows = [[{}, {}]]

    rows.push(
      [
        { text: 'Subtotal', fontSize: 10 },
        { text: penceToPounds(totals.totalBillToClient - totals.productionFee), alignment: 'right', fontSize: 10 }
      ]
    )

    if (project.production_fee_pc > 0) {
      rows.push([
        { text: `Mark-up @ ${project.production_fee_pc}% on all external services`, fontSize: 10 },
        { text: penceToPounds(totals.productionFee), alignment: 'right', fontSize: 10 }
      ])
    }

    if (project.client_discount_pc > 0) {
      rows.push([
        { text: `Total discount @ ${Number(project.client_discount_pc).toFixed(0)}%`, fontSize: 10 },
        { text: penceToPounds(totals.totalBillToClient - totals.totalClientDiscount), alignment: 'right', fontSize: 10 }
      ])
    }

    rows.push(
      [
        { text: 'Subtotal (Excl. VAT)', fontSize: 10 },
        {
          text: penceToPounds(totals.totalClientDiscount),
          alignment: 'right',
          fontSize: 10
        }
      ],
      [
        { text: 'VAT rate', fontSize: 10 },
        { text: `${constants.VAT_RATE}%`, alignment: 'right', fontSize: 10 }
      ],
      [
        { text: 'Grand Total', bold: true, fontSize: 12 },
        {
          text: penceToPounds(
            Number.parseFloat(
              (totals.totalClientDiscount + (constants.VAT_RATE / 100) *
              totals.totalClientDiscount)
            )
          ),
          bold: true,
          alignment: 'right',
          fontSize: 12
        }
      ]
    )

    return rows
  }

  return {
    pageMargins: [40, 30, 40, 100],
    footer: function (currentPage, pageCount) {
      if (currentPage === pageCount) {
        return [
          {
            text: [
              'Budget created by ',
              {
                text: 'Prodbud.app',
                color: '#a0136a',
                link: 'https://prodbud.app/',
                decoration: 'underline'
              }
            ],
            margin: [40, 20, 40, 2],
            style: 'footerHeader'
          },
          {
            text: 'Video production budgeting software',
            margin: [40, 2],
            style: 'footerText'
          }
        ]
      }
    },
    content: [
      { text: `${project.title} - Budget Summary`, style: 'header', margin: [0, 20, 0, 0] },
      {
        columns: [
          {
            text: [
              { text: 'Estimate for: ', style: 'subHeader' },
              { text: `${project.client}`, style: 'subHeaderItalic' }
            ],
            width: '70%',
            margin: [0, 0, 0, 20]
          }
        ]
      },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: [120, 200, '*', '*', '*'],
          body: buildTableRows(showAllItems)
        }
      },
      {
        layout: 'headerLineOnly',
        margin: [0, 20, 0, 0],
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            ...buildTotalsRows()
          ]
        }
      }
    ],
    defaultStyle: {
      font: 'SourceSerifPro',
      color: '#333333'
    },
    styles: {
      header: {
        fontSize: 24,
        bold: true,
        color: '#a0136a'
      },
      subHeader: {
        font: 'SourceSerifProLight',
        fontSize: 16
      },
      subHeaderItalic: {
        font: 'SourceSerifProLight',
        fontSize: 16,
        italics: true
      },
      footerHeader: {
        fontSize: 10,
        bold: true
      },
      footerText: {
        fontSize: 10,
        color: '#7f7f7f'
      },
      tableHeader: {
        font: 'SourceSansPro',
        fontSize: 12,
        bold: true,
        color: '#a0136a'
      },
      RowText: {
        font: 'SourceSansProLight',
        bold: false,
        fontSize: 10,
        lineHeight: 1.5
      },
      RowTextUnits: {
        font: 'SourceSansProLight',
        bold: false,
        fontSize: 10,
        lineHeight: 1.5,
        alignment: 'right'
      },
      RowTextDepartment: {
        margin: [0, 5, 0, 0],
        font: 'SourceSansPro',
        color: '#333333',
        fontSize: 12,
        bold: true,
        lineHeight: 1.5
      }
    },
    images: {}
  }
}

/**
 * function returns a function that debounces the call
 * to the func param, with the given delay param.
 * @param {object} func
 * @param {number} delay
**/
export function debounce (func, delay) {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}
