<template>
<tbody class="text-text border-b-2 border-primary-light">
    <tr
      v-for="projectlineitem in this.$props.projectLineItems"
      class="align-middle border-t border-primary-light"
      :class="doubleMarkup(projectlineitem) ? 'line_item_double_markup' : lineUsed(projectlineitem) ? 'line_item_entered' : ''"
      :key="projectlineitem.id"
    >
      <td
        v-if="customPli(projectlineitem)"
        :class="tdClassObj"
        id="name"
      >
        <ProjectLineItemInput
          class="pl-0"
          @blur="handleBlur(projectlineitem)"
          inputType="text"
          placeholder="Enter a custom item..."
          v-model="projectlineitem.name"
          :disabled="inputDisabled(projectlineitem)"
          noBackgroundColour="true"
        />
      </td>
      <td
        v-else
        class="py-2"
        id="name"
      >
        {{ projectlineitem.line_item.name }}
      </td>
      <td class="text-center" id="notes">
        <button
          class="p-2 text-primary hover:text-primary-dark"
          :class="projectlineitem.notes ? 'text-primary' : ''"
          @click="openModal(projectlineitem)"
        >
          <span>
            <NoteIcon
              class="h-5 w-5 transition-colors stroke-current"
              :class="projectlineitem.notes ? 'text-green-500' : ''"
            />
          </span>
        </button>
      </td>
      <td
        v-if="recMode"
        class="bg-primary-lightest py-2 pl-2"
      >
        <rec-modal-link
          :disabled="archived"
          :name="this.pliName(projectlineitem)"
          :pli="projectlineitem"
        />
      </td>
      <unit-select
        class="p-0"
        :alt="projectlineitem.line_item"
        :customPli="customPli(projectlineitem)"
        :disabled="recMode || archived"
        @blur="handleBlur(projectlineitem)"
        v-model="projectlineitem.unit"
      />

      <!-- quantity should only allow input if units are not days -->
      <td
        class="w-6"
        :class="tdClassObj"
        id="quantity"
      >
        <ProjectLineItemInput
          class="p-2 font-mono"
          @focus="$event.target.select()"
          @blur="handleBlur(projectlineitem)"
          inputType="100x"
          v-model.number="projectlineitem.quantity"
          :disabled="recMode || archived"
          :placeholder="quantityPlaceholder(projectlineitem)"
        />
      </td>

      <td
        id="defaultRate"
        class="font-mono"
      >
        <span
          class="inline-block p-2 h-full w-full"
          :class="defaultRateClass(projectlineitem)"
        >
          {{
            !customPli(projectlineitem)
              ? penceToPounds(projectlineitem.line_item.rate_pence)
              : '-'
          }}
        </span>
      </td>

      <td
        class=""
        :class="tdClassObj"
        id="costToOmni"
      >
        <ProjectLineItemInput
          class="p-2 font-mono min-w-100px"
          @blur="handleBlur(projectlineitem)"
          inputType="money"
          v-model.number="projectlineitem.internal_cost_pence"
          :disabled="recMode || archived"
        />
      </td>

      <td
        class=""
        :class="tdClassObj"
        id="costToClient"
      >
        <ProjectLineItemInput
        class="p-2 font-mono min-w-100px"
        @blur="handleBlur(projectlineitem)"
        inputType="money"
        v-model.number="projectlineitem.external_cost_pence"
        :disabled="recMode || archived"/>
      </td>

            <!-- prepTimeDays and workTimeDays should only allow input if units are days -->
            <td
        v-if="unitDays(projectlineitem)"
        class=""
        :class="tdClassObj"
        id="prepTimeDays"
      >
        <ProjectLineItemInput
          class="p-2 font-mono"
          @blur="handleBlur(projectlineitem)"
          inputType="100x"
          placeholder="days"
          v-model.number="projectlineitem.prep_time_days"
          :disabled="recMode || archived"
        />
      </td>
      <td
        v-else
        class="bg-primary-lightest py-2 pl-2"
        :class="tdClassObj"
      ></td>
      <td
        v-if="unitDays(projectlineitem)"
        :class="tdClassObj"
        id="workTimeDays"
      >
        <ProjectLineItemInput
          class="max-w-full p-2 font-mono"
          @blur="handleBlur(projectlineitem)"
          inputType="100x"
          placeholder="days"
          v-model.number="projectlineitem.work_time_days"
          :disabled="recMode || archived"
        />
      </td>
      <td
        v-else
        class="bg-primary-lightest py-2 pl-2"
        :class="tdClassObj"
      ></td>

      <!-- std overtime is only relevant if line item has an apa ot grade -->
      <td
        v-if="stdOtValid(projectlineitem) && unitDays(projectlineitem)"
        class=""
        :class="tdClassObj"
        id="stdOtHours"
      >
        <ProjectLineItemInput
          class="p-2 font-mono"
          @blur="handleBlur(projectlineitem)"
          inputType="number"
          placeholder="hours"
          v-model.number="projectlineitem.std_ot_hours"
          :disabled="recMode || archived"
        />
      </td>

      <td
        v-else
        class="bg-primary-lightest"
        :class="tdClassObj"
      >
        <span
          class="inline-block h-full w-full p-2"
          :class="this.recMode || this.archived ? 'bg-primary-light' : ''">
            -
        </span>
      </td>

      <td
        class="bg-primary-lightest"
        id="breakEvenCost"
      >
        <span :class="this.recMode || this.archived ? 'inline-block w-full bg-primary-light py-2 px-4' : ''">
          {{ penceToPounds(breakEvenCost(projectlineitem)) }}
        </span>
      </td>

      <td
        class="bg-primary-lightest"
        id="totalBillable"
      >
        <span :class="this.recMode || this.archived ? 'inline-block w-full bg-primary-light py-2 px-4' : ''">
          {{ penceToPounds(totalBillable(projectlineitem)) }}
        </span>
      </td>

      <td
        class="bg-primary-lightest"
        id="margin"
      >
        <span :class="this.recMode || this.archived ? 'inline-block w-full bg-primary-light py-2 px-4' : ''">
          {{penceToPounds(margin(projectlineitem)) }}
        </span>
      </td>

      <!-- markup -->
      <td
        class=""
        id="internal"
      >
        <select
          class="p-2 focus:outline-focus shadow-remove"
          :class="recMode || archived ? 'bg-primary-lightest': ''"
          :disabled="recMode || archived"
          @blur="handleBlur(projectlineitem)"
          v-model="projectlineitem.internal"
        >
          <option v-bind:value="false">Yes</option>
          <option v-bind:value="true">No</option>
        </select>
      </td>

      <td
        class="text-primary hover:text-primary-dark text-center"
        id="options"
      >
        <button
          class="p-2"
          :class="recMode || archived ? 'text-text-metadata hover:text-text-metadata' : ''"
          @click.prevent="deletePli(projectlineitem)"
          :disabled="inputDisabled(projectlineitem)"
        >
          <Icon
            class="fill-current transition-all"
            name="close"
          />
        </button>
      </td>
    </tr>
</tbody>
</template>

<script>
import * as utils from '@/components/js/utils'
import Icon from '@/components/icon.vue'
import NoteIcon from '@/components/svg/iconNote.vue'
import ProjectLineItemInput from '@/components/projectLineItemInput.vue'
import RecModalLink from './recModalLink.vue'
import UnitSelect from '@/components/projectLineItemUnitSelect.vue'
import { DEFAULT_SELECT_STR } from '@/components/js/constants'
import { mapState } from 'vuex'

export default {
  name: 'ProjectLineItems',
  components: {
    Icon,
    NoteIcon,
    ProjectLineItemInput,
    RecModalLink,
    UnitSelect
  },
  data () {
    const lastPost = 0
    const delay = 1000

    return {
      lastPost: lastPost,
      delay: delay
    }
  },
  computed: {
    ...mapState({
      recMode: state => state.projects.project.rec_mode,
      archived: state => !state.projects.project.is_active,
      project: state => state.projects.project
    }),
    tdClassObj (pli) {
      return {
        'bg-primary-lightest': this.recMode || this.archived
      }
    }
  },
  methods: {
    quantityPlaceholder (projectlineitem) {
      if (utils.unitDays(projectlineitem)) {
        return '1'
      } else {
        return '0'
      }
    },
    defaultRateClass (projectlineitem) {
      let cls = ''
      if (this.recMode || this.archived) {
        cls = cls.concat('bg-primary-light')
      }
      if (!this.customPli(projectlineitem)) {
        cls.concat('text-opacity-0')
      }
      return cls
    },
    inputDisabled (pli) {
      if (this.recMode) return true
      return this.archived && !utils.customPli(pli)
    },
    internalClassObj (projectlineitem) {
      if (projectlineitem.internal) {
        return 'bg-primary'
      } else if (this.recMode || this.archived) {
        return 'bg-primary-lightest'
      }
      return ''
    },
    customPli (projectlineitem) { return utils.customPli(projectlineitem) },
    nightOtValid (projectlineitem) {
      return utils.nightOtValid(projectlineitem)
    },
    stdOtValid (projectlineitem) {
      return utils.stdOtValid(projectlineitem)
    },
    prepTimeDays (projectlineitem) {
      return projectlineitem.prep_time_days
    },
    quantity (projectlineitem) {
      return projectlineitem.quantity
    },
    stdOtHours (projectlineitem) {
      return projectlineitem.std_ot_hours
    },
    workTimeDays (projectlineitem) {
      return projectlineitem.work_time_days
    },
    billableNightOtRate (projectlineitem) {
      return utils.billableNightOtRate(projectlineitem)
    },
    billableStdOtRate (projectlineitem) {
      return utils.billableStdOtRate(projectlineitem)
    },
    breakEvenCost (projectlineitem) {
      return utils.breakEvenCost(projectlineitem)
    },
    costRate (projectlineitem) {
      return utils.costRate(projectlineitem)
    },
    internalBECost (projectlineitem) {
      return utils.internalBECost(projectlineitem)
    },
    margin (projectlineitem) {
      return utils.totalBillable(projectlineitem) -
      utils.breakEvenCost(projectlineitem)
    },
    nightOtRate (projectlineitem) {
      return utils.nightOtRate(projectlineitem)
    },
    stdOtRate (projectlineitem) {
      return utils.stdOtRate(projectlineitem)
    },
    prodBECost (projectlineitem) {
      return utils.externalBECost(projectlineitem)
    },
    totalBillable (projectlineitem) {
      return utils.totalBillable(projectlineitem)
    },
    DEFAULT_SELECT_STR (projectlineitem) {
      return DEFAULT_SELECT_STR
    },
    unitDays (projectlineitem) {
      return utils.unitDays(projectlineitem)
    },
    pliName (projectlineitem) {
      if (this.customPli(projectlineitem)) {
        return projectlineitem.name
      } else {
        return projectlineitem.line_item.name
      }
    },
    lineUsed (projectlineitem) {
      return utils.lineUsed(projectlineitem)
    },
    doubleMarkup (projectlineitem) {
      // markup has possibly been added twice (via mark-up and margin)
      return this.margin(projectlineitem) > 0 && projectlineitem.internal !== true
    },
    deletePli: function (projectlineitem) {
      if (!this.inputDisabled(projectlineitem)) {
        this.$store.commit('setModal', {
          title: `
            Are you sure you want to delete
             ${this.pliName(projectlineitem)
              ? this.pliName(projectlineitem) : 'this item'}?
          `,
          bodyText: '',
          visible: true,
          editable: false,
          type: 'confirmation',
          onClick: function () {
            this.$store.dispatch('deleteProjectLineItem', projectlineitem.id)
          }.bind(this)
        })
      }
    },
    handleBlur: function (projectlineitem) {
      // debounce handleBlur for when user is tabbing through fields
      if (this.$data.lastPost >= (Date.now() - this.$data.delay)) {
        return
      }

      this.$data.lastPost = Date.now()

      if (projectlineitem) {
        this.$store.dispatch('patchProjectLineItem', projectlineitem)
      }
    },
    openModal: function (projectlineitem) {
      this.$store.commit('setModal', {
        title: this.pliName(projectlineitem),
        bodyText: projectlineitem.notes,
        visible: true,
        editable: this.project.is_active,
        type: 'notes',
        onClick: function () {
          projectlineitem.notes = this.$store.state.modalModule.modal.bodyText
          this.$store.dispatch('patchProjectLineItem', projectlineitem)
        }.bind(this)
      })
    },
    openRecModal: function (projectlineitem) {
      const rec = projectlineitem.rec || {
        invoice_received: false,
        invoice_amount: null,
        invoice_no: null,
        notes: '',
        paid: false
      }

      this.$store.commit('setRecModal', {
        title: this.pliName(projectlineitem),
        editable: true,
        visible: true,
        errors: {},
        rec: JSON.parse(JSON.stringify(rec)),
        onClick: function () {
          // update pli.rec with data entered in modal
          projectlineitem.rec = this.$store.state.modalModule.recModal.rec

          // set this new recs pli.id if it doesn't already have one
          if (!projectlineitem.rec.id) {
            projectlineitem.rec.project_line_item = projectlineitem.id
          }

          // validation
          if (
            isNaN(projectlineitem.rec.invoice_amount) ||
            projectlineitem.rec.invoice_amount === 0
          ) {
            this.$store.commit(
              'setRecModal',
              { ...rec, errors: 'Invoice Amount is invalid.' }
            )
          }

          // patch rec to backend
          this.$store.dispatch('patchRec', {
            id: projectlineitem.rec.id || null,
            rec: projectlineitem.rec
          })
        }.bind(this)
      })
    },
    intToString: utils.intToString,
    penceToPounds: utils.penceToPounds,
    poundsToPence: utils.poundsToPence
  },
  props: ['projectLineItems']
  // emits: ['update:modelValue']
}
</script>
